(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name vehicles.controller:VehiclesCtrl
   *
   * @description
   *
   */
  angular
    .module('events.vehicles')
    .controller('VehiclesCtrl', VehiclesCtrl);

  function VehiclesCtrl(event, vehicles, $mdDialog) {
    var vm = this;
    vm.event = event;
    vm.vehicles = vehicles;
    vm.vehicle = vehicles[0];
    vm.editPerson = editPerson;

    function editPerson(ev, person, vehicle) {
      $mdDialog.show({
        controller: EditPersonController,
        templateUrl: 'events/vehicles/edit-person.tpl.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true,
        locals: {
          person: person,
          vehicle: vehicle
        }
      });
    }

    function EditPersonController($scope, $mdDialog, person, vehicle) {
      $scope.person = person;
      $scope.vehicle = vehicle;
      $scope.hide = function () {
        $mdDialog.hide();
      };
      $scope.cancel = function () {
        $mdDialog.cancel();
      };
      $scope.answer = function (answer) {
        $mdDialog.hide(answer);
      };
      $scope.confirmRemoveFromVehicle = confirmRemoveFromVehicle;
    }

    function confirmRemoveFromVehicle(ev, person, vehicle) {

      // todo add person's name to content message

      var confirm = $mdDialog.confirm()
        .title('Confirm removal')
        .textContent('Remove this person from this vehicle?')
        .targetEvent(ev)
        .ok('OK')
        .cancel('Cancel');

      $mdDialog.show(confirm).then(function () {
        console.log('confirmed');
      }, function () {
        console.log('denied');
      });
    }
  }
}());
